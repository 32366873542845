import GaugeChart from "react-gauge-chart/dist/GaugeChart";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { CircularProgress } from "@material-ui/core";

const SpeedMeeterChart = ({
  testName,
  risk,
  get_the_color,
  riskText,
  loading,
}) => {
  const loadingIcon = (
    <CircularProgress
      size={20}
      thickness={3}
      style={{ color: "#ff6500", marginRight: "3px" }}
    />
  );

  return (
    <div className="speed-meeter-chart">
      <h1 dangerouslySetInnerHTML={{ __html: testName }}></h1>

      <div className="speedchart__">
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={4}
          colors={["green", "#d48e04", "#d46604", "#c90700"]}
          arcWidth={0.2}
          percent={risk}
          hideText={true}
          animDelay={10}
          style={{ width: "100%", height: "100%" }}
        />

        <div className="risk-number-content">
          <p
            style={{
              textAlign: "center",
              fontSize: "30px",
              fontWeight: "bold",
              color: get_the_color(risk * 100),
            }}
          >
            {loading ? (
              loadingIcon
            ) : (
              <>
                <span dangerouslySetInnerHTML={{ __html: riskText }}></span>
                {risk * 100 === 100
                  ? risk * 100
                  : parseFloat(risk * 100).toFixed(1)}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SpeedMeeterChart);
