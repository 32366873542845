import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

//uiti
import AccountsBtn from "../../utilities/Buttons/AccountsBtn/AccountBtn";

// services
import { showErrorMessage } from "../formsService";

//images
import logoImg from "../../../images/icon-1.png";
import hidePass from "../../../images/imgpsh_fullsize_anim.png";
import showPass from "../../../images/imgpsh_fullsize_anim-45.png";

import "./styles.scss";

import { ifLoading } from "../../utilities/loading/ifLoading";
import { login } from "../../services/auth";
import { getContent } from "../../services/content";

import {
  setUserId,
  setUserImg,
  setUserName,
  setUserIdCompany,
} from "../../../redux/actions/userActions";

const LoginComp = ({ props }) => {
  const [checkTerms, setCheckTerms] = useState(true);
  // const [isvalidatedEmail, setIsValidatedEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);
  const [typepassword, settypepassword] = useState("password");
  // const [showFormError, setShowFormError] = useState(false);
  const [userNotFound, setuserNotFound] = useState(false);
  // const [itIsNotAnActiveUser, setItIsNotAnActiveUser] = useState(false);
  // const [userIsAlreadyLoggedIn, setUserIsAlreadyLoggedIn] = useState("");
  const [title, settitle] = useState("");
  const [forgetPassText, setforgetPassText] = useState("");
  const [loadingText, setloadingText] = useState("");
  const [loginText, setloginText] = useState("");
  const [emailError, setemailError] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [smthWentWrongError, setsmthWentWrongError] = useState("");
  const [notActiveUserError, setnotActiveUserError] = useState("");
  const [keepPassword, setkeepPassword] = useState("");

  const go = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setTextLoading(true);
    getAllPageContent();
  }, []);

  const GreenCheckbox = withStyles({
    root: {
      color: "#bcbcbc",
      "&$checked": {
        color: "#bcbcbc",
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const getAllPageContent = () => {
    getContent("login_page_content").then((data) => {
      const arr = data.content;
      settitle(arr[0].value);
      setloginText(arr[1].value);
      setforgetPassText(arr[2].value);
      setkeepPassword(arr[3].value);
      setloadingText(arr[4].value);
      setemailError(arr[5].value);
      setpasswordError(arr[6].value);
      setsmthWentWrongError(arr[7].value);
      setnotActiveUserError(arr[8].value);
      setTextLoading(false);
    });
  };

  async function login_submit(event) {
    setLoading(true);
    const { email, password } = event;
    try {
      const res = await login(email, password);
      const userdata = res.user;
      if (userdata) {
        setLoading(false);
        handleThedispatchs(userdata);
        go.push("/chart");
      } else {
        setuserNotFound(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleThedispatchs(data) {
    dispatch(setUserName(data.first_name + " " + data.last_name));
    dispatch(setUserId(data.id));
    dispatch(setUserIdCompany(data.id_company));
    dispatch(setUserImg(data.profile_image ? data.profile_image : null));
  }
  const handleShowPass = () => {
    if (typepassword === "password") settypepassword("text");
    if (typepassword !== "password") settypepassword("password");
  };

  const handleChange = (event) => {
    setCheckTerms(event.target.checked);
  };

  // if (textLoading) {
  //   return ifLoading();
  // }

  return (
    <div className="login-container">
      <form
        onSubmit={handleSubmit(login_submit)}
        className="login-section input-group"
      >
        <img src={logoImg} className="logo-img" alt="" />
        <p
          className="login-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        <div className="input-container">
          <input
            className={`form-control email ${errors.email && "input-error"}`}
            type="text"
            {...register("email", { required: true })}
            placeholder="Adresse mail"
          />

          <div className="password-input-section">
            <input
              className={`form-control ${errors.password && "input-error"}`}
              type={typepassword}
              {...register("password", { required: true })}
              placeholder="Mot de passe"
            />
            <div className="show-hide-pass">
              {/* icon show pass */}
              <IconButton onClick={handleShowPass}>
                {typepassword === "password" ? (
                  <img src={showPass} alt="" />
                ) : (
                  <img src={hidePass} alt="" />
                )}
              </IconButton>
            </div>
          </div>
          <AccountsBtn
            type="submit"
            className={`submit-btn ${loading && "loading-btn"}`}
          >
            {loading ? (
              <span dangerouslySetInnerHTML={{ __html: loadingText }}></span>
            ) : (
              <span dangerouslySetInnerHTML={{ __html: loginText }}></span>
            )}
          </AccountsBtn>

          <div className="remember-forget-pass">
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={checkTerms}
                  onChange={handleChange}
                  name="checkedG"
                />
              }
              label={
                <span dangerouslySetInnerHTML={{ __html: keepPassword }}></span>
              }
              className="terms-checkbox"
            />
            <Link
              to="/auth/forgotpass"
              dangerouslySetInnerHTML={{ __html: forgetPassText }}
            ></Link>
          </div>
        </div>

        <div className="error-section">
          {errors.email && showErrorMessage(emailError)}
          {errors.password && showErrorMessage(passwordError)}
          {/* {showFormError && showErrorMessage(smthWentWrongError)} */}
          {/* {itIsNotAnActiveUser && showErrorMessage(notActiveUserError)} */}
          {userNotFound && showErrorMessage("Ces coordonnées sont erronées.")}
          {/* {userIsAlreadyLoggedIn &&
            showErrorMessage(
              "L'utilisateur est déjà connecté depuis un autre appareil"
            )} */}

          {/* {!isvalidatedEmail && showErrorMessage("This must be a valid email")} */}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({}) => ({});

export default connect(mapStateToProps)(LoginComp);
