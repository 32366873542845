import React, { useEffect, useState } from "react";
import "./styles.scss";
import { getContent } from "../../../services/content";
import Tooltip from "@mui/material/Tooltip";

import infoIconImg from "../../../../images/checked.png";

const PhisingLevels = ({ handleChartLineLevel, curLevel }) => {
  const [title, settitle] = useState("");
  const [levelOneText, setlevelOneText] = useState("");
  const [levelTwoText, setlevelTwoText] = useState("");
  const [levelThreeText, setlevelThreeText] = useState("");
  const [levelFourText, setlevelFourText] = useState("");
  const [levelOneInfo, setlevelOneInfo] = useState("");
  const [levelTwoInfo, setlevelTwoInfo] = useState("");
  const [levelThreeInfo, setlevelThreeInfo] = useState("");
  const [levelFourInfo, setlevelFourInfo] = useState("");

  useEffect(() => {
    getAllPageContent();
  }, []);

  const getAllPageContent = () => {
    getContent("level_page_content").then((data) => {
      const arr = data.content;
      settitle(arr[0].value);
      setlevelOneText(arr[1].value);
      setlevelTwoText(arr[2].value);
      setlevelThreeText(arr[3].value);
      setlevelFourText(arr[4].value);
      setlevelOneInfo(<p dangerouslySetInnerHTML={{__html: arr[5].value}}></p>);
      setlevelTwoInfo(<p dangerouslySetInnerHTML={{__html: arr[6].value}}></p>);
      setlevelThreeInfo(<p dangerouslySetInnerHTML={{__html: arr[7].value}}></p>);
      setlevelFourInfo(<p dangerouslySetInnerHTML={{__html: arr[8].value}}></p>);
    });
  };

  return (
    <div className="phising-info phising-levels">
      <div className="content">
        <div className="title">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        </div>

        <div
          color="error"
          variant="outlined"
          className="info level"
          onClick={() => handleChartLineLevel(1)}
        >
          <div>
            <span dangerouslySetInnerHTML={{ __html: levelOneText }}></span>
            <Tooltip title={levelOneInfo} arrow={true}>
              <img src={infoIconImg} alt="" />
            </Tooltip>
          </div>
          <span
            className="level_lines"
            style={{ backgroundColor: "#ff3333" }}
          ></span>
        </div>
        <div className="info level" onClick={() => handleChartLineLevel(2)}>
          <div>
            <span dangerouslySetInnerHTML={{ __html: levelTwoText }}></span>
            <Tooltip title={levelTwoInfo} arrow={true}>
              <img src={infoIconImg} alt="" />
            </Tooltip>
          </div>
          <span
            className="level_lines"
            style={{ backgroundColor: "#ffc600" }}
          ></span>
        </div>
        <div className="info level" onClick={() => handleChartLineLevel(3)}>
          <div>
            <span dangerouslySetInnerHTML={{ __html: levelThreeText }}></span>
            <Tooltip title={levelThreeInfo} arrow={true}>
              <img src={infoIconImg} alt="" />
            </Tooltip>
          </div>
          <span
            className="level_lines"
            style={{ backgroundColor: "#00a0ff" }}
          ></span>
        </div>
        <div className="info level" onClick={() => handleChartLineLevel(4)}>
          <div>
            <span dangerouslySetInnerHTML={{ __html: levelFourText }}></span>
            <Tooltip title={levelFourInfo} arrow={true}>
              <img src={infoIconImg} alt="" />
            </Tooltip>
          </div>
          <span
            className="level_lines"
            style={{ backgroundColor: "#008000" }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default PhisingLevels;
