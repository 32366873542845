
//dev api
// const mainApi = "https://phishing.dev-des-oies.com/"

// produciton api
const mainApi = "https://admin.prevention-professionnelle.org/";
// const mainApi = "https://phishing.dev-des-oies.com/"

export const prefixApi = `${mainApi}api/`
export const fileApi = `${mainApi}/uploads/resources/`;
export const prefixFileApi =`${mainApi}/pdf`
export const prefix_api_set_img = `${mainApi}/uploads/profile_images/`
export const prefix_api_set_img_content =`${mainApi}/uploads/content/`



export const prefixHeaders = {
    Authorization: "Basic " + btoa("api" + ":" + "P4r0|@876!api"),
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    Accept: "application/json, text/javascript",
  }
  