import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// import CustomNextBtn from "../../utilities/NextButton";
import "./styles.scss";
import { showErrorMessage } from "../formsService";

import logoImg from "../../../images/icon-1.png";
// import Alert from "../../utilities/AlertModal/AlertModal";
import { email_reset_password } from "../../services/auth";
import { getContent } from "../../services/content";
import Alert from "../../utilities/modals/AlertModal/Alert";
import AccountsBtn from "../../utilities/Buttons/AccountsBtn/AccountBtn";

const ForgotPass = () => {
  const [showFormError, setshowFormError] = useState(false);
  const [title, settitle] = useState("");
  const [goToLogin, setgoToLogin] = useState("");
  const [btnText, setBtnText] = useState("");
  const [loading, setLoading] = useState("");
  const [emailError, setemailError] = useState("");
  const [smthWentWrongError, setsmthWentWrongError] = useState("");
  const [alertText, setalertText] = useState("");
  const [emailDoesntExistText, setemailDoesntExistText] = useState("");

  const [alertStatus, setalertStatus] = useState(false);
  const [emailDoesntExist, setemailDoesntExist] = useState(false);

  useEffect(() => {
    setLoading(true);
    getContent("forget_password_page").then((data) => {
      const arr = data.content;
      settitle(arr[0].value);
      setgoToLogin(arr[1].value);
      setBtnText(arr[2].value);
      setemailError(arr[3].value);
      setsmthWentWrongError(arr[4].value);
      setalertText(arr[5].value);
      setemailDoesntExistText(arr[6].value);
      setLoading(false);
    });
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const forgetPass = (e) => {
    const { email } = e;
    setemailDoesntExist(false)
    handleSendEmail(email)
  };

  async function handleSendEmail(email) {
    const res = await email_reset_password(email);
    if (res.status == 1) {
      setalertStatus(true);
    } else if (res.status === 0) {
      setemailDoesntExist(true);
    }
  }

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress
          size={50}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    );
  }

  return (
    <div className="forgot-pass-container">
      <Alert
        alertStatus={alertStatus}
        handleCloseAlert={() => setalertStatus(false)}
        title={alertText}
      />
      <form onSubmit={handleSubmit(forgetPass)} className="forgot-pass-section">
        <img src={logoImg} className="logo-img" alt="" />
        <p
          className="forget-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        <div className="input-container">
          <input
            className={`form-control ${errors.email && "input-error"}`}
            autoComplete="true"
            type="text"
            {...register("email", { required: true })}
            placeholder="Votre adresse mail"
          />
          <AccountsBtn onClick={handleSubmit(forgetPass)} typeBtn="submit">
            <span dangerouslySetInnerHTML={{ __html: btnText }}></span>
          </AccountsBtn>
          <Link
            className="go-to-login"
            to="/auth/login"
            dangerouslySetInnerHTML={{ __html: goToLogin }}
          ></Link>
        </div>

        <div className="error-section">
          {errors.email && showErrorMessage(emailError)}
          {showFormError && showErrorMessage(smthWentWrongError)}
          {emailDoesntExist && showErrorMessage(emailDoesntExistText)}

          {/* {!isvalidatedEmail && showErrorMessage("This must be a valid email")} */}
        </div>
      </form>
    </div>
  );
};

export default ForgotPass;
