import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import CustomizedAxisTick from "./CustomizedAxisTick";
import CustomizeDot from '../CustmizedDot/CustomizeDot'
function RenderContentToolTip({ active, payload, label }) {
  if (active && payload && payload.length) {
    if (payload[0].payload.uv == 0) return null;
    return (
      <div
        style={{
          opacity: 0.7,
          fontSize: 13,
          backgroundColor: "white",
          padding: 10,
        }}
      >
        Risque: {payload[0].payload.risk}
      </div>
    );
  }
  return null;
}
const ChartComp = ({
  mxEelementInTest,
  testsArr,
  curLevel,
  onClickDot,
  activeColor,
  curYear,
}) => {
 
  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart
        width={460}
        height={600}
        padding={{ top: 15 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="category"
          dataKey="chartName"
          interval={0}
          padding={{ left: 0, right: 20 }}
          allowDuplicatedCategory={false}
          tick = {<CustomizedAxisTick />}
        />
        <YAxis
          padding={{ top: 15 }}
          dataKey="risk"
          domain={[0, Math.max(mxEelementInTest, 100)]}
          allowDecimals={true}
          allowDataOverflow={false}
          interval={0}
       
        />

        <Tooltip content={RenderContentToolTip} />
        <Legend />
        {testsArr.map((item) => {
          return (
            <Line
              isAnimationActive={false}
              dataKey="risk"
              data={item.level}
              name={item.levelIdx}
              key={item.levelIdx}
              stroke={item.color}
              strokeWidth={5}
              activeDot={{
                onClick: (event, payload) =>
                  onClickDot(payload.payload, curYear),
                r: 8,
              }}
              dot={<CustomizeDot activeColor={activeColor} />}
              legendType="none"
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartComp;
