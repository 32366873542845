import { Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Logo from "../../../images/resources/logo.png";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./styles.scss";
import UserAvatar from "../../Profile/userAvatar/UserAvatar";
import {
  setUserId,
  setUserIdCompany,
  setUserImg,
  setUserName,
} from "../../../redux/actions/userActions";
import { useHistory } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 150,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      "& .MuiListItem-button": {
        color: "red",
      },
    },
  },
}))(MenuItem);

// const Header = ({ handleLogOut, common, user }) => {
const Header = ({ user, appRedux }) => {
  const go = useHistory();
  const dispatch = useDispatch();
  const [logoutBtn, setlogoutBtn] = useState("");
  const [username, setusername] = useState("");
  const [name, setname] = useState([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElHumberger, setAnchorElHumberger] = useState(null);
  const [historyBtn, sethistoryBtn] = useState("");
  const [defaultUserNmae, setdefaultUserNmae] = useState();

  const [headerState, setheaderState] = useState(true);

  useEffect(() => {
    checkThePath(appRedux.curPath, "auth");
  }, [appRedux.curPath]);

  async function handleLogOut() {
    setAnchorEl(null);
    handleThedispatches();
    go.push("/auth/login");
  }
  function handleThedispatches() {
    dispatch(setUserName(null));
    dispatch(setUserId(null));
    dispatch(setUserIdCompany(null));
    dispatch(setUserImg(null));
  }
  const handleClickHumbergerMenu = (event) => {
    setAnchorElHumberger(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElHumberger(null);
  };
  function checkThePath(path = "", word = "") {
    setheaderState(path.includes(word));
  }

  return (
    <div className="header-container">
      <div className="audit-section">
        <img className="img-fluid" src={Logo} alt="" />
      </div>
      <div className="logout-continer">
        {!headerState && (
          <div className="menu-content">
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={handleClick}
              className="dropdown-btn"
            >
              <div className="avatar-container">
                {user.img && (
                  <UserAvatar
                    imgSrc={user.img}
                    imgWidth={50}
                    imgHeight={50}
                    hideIcon={true}
                  />
                )}
              </div>
              <>
                <span>{user.name}</span>
                <KeyboardArrowDownIcon />
              </>
            </Button>

            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                    textAlign: "center",
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/profile/change-pass`}
                >
                  Mot de passe
                </a>
              </StyledMenuItem>

              <StyledMenuItem onClick={handleLogOut}>
                Déconnexion
              </StyledMenuItem>
            </StyledMenu>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ appRedux, user }) => ({
  appRedux,
  user,
});
export default connect(mapStateToProps)(Header);
