import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {  Route, withRouter } from 'react-router-dom'
import { set_cur_path} from '../../../redux/actions/projectactions'

const PublicRoute = ({ user, component: Component, ...rest }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(set_cur_path(window.location.pathname))
    }, [])
    return (
        <Route {...rest}
            component={Component}
        />
    )
}

const mapStateToProps = ({ user }) => ({
    user
})


export default withRouter(connect(mapStateToProps)(PublicRoute))