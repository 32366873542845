import { prefixApi, prefixHeaders } from "./prefix";

const speedChartApi = prefixApi + "reports/last_test_risk_percentage/";
const chartLineApi = prefixApi + "reports/retrieve_by_year_tests/";
const yearsChartApi = prefixApi + "tests/retrieve_active_years/"; // + {id_company}
const retrieve_last_12_months_by_level_tests =
  prefixApi + "reports/retrieve_last_12_months_by_level_tests/";
const retrieve_by_year_and_level_tests =
  prefixApi + "reports/retrieve_by_year_and_level_tests/"; // + {id_company}

const retrieve_tests_grouped_by_level =
  prefixApi + "reports/retrieve_tests_grouped_by_level/"; /** company-year */

export const get_tests_grouped_by_level = async (
  id_company,
  curYear,
  handleError
) => {
  try {
    const res = fetch(
    //   `${retrieve_tests_grouped_by_level}${id_company}/${curYear}`,
    `${retrieve_tests_grouped_by_level}${id_company}/${curYear}`,
      {
        method: "GET",
        headers: prefixHeaders,
      }
    ).then((res) => res.json());
    return await res;
  } catch (error) {
    handleError(error);
  }
};

export async function getSpeedchartData(id_company) {
  try {
    const res = fetch(speedChartApi + id_company, {
      method: "GET",
      headers: prefixHeaders,
    }).then((res) => res.json());
    return await res;
  } catch (error) {
    throw new Error("some thing wring");
  }
}

export async function getChartLineAPI(id_company, curYear) {
  return fetch(`${chartLineApi}${id_company}/${curYear}`, {
    // return fetch(`${chartLineApi}${id_company}/2022`, {
    method: "GET",
    headers: prefixHeaders,
  }).then((res) => res.json());
}

export async function getChartLineYears(id_company) {
  const jsonRes = await fetch(`${yearsChartApi}${id_company}`, {
    method: "GET",
    headers: prefixHeaders,
  });
  const res = await jsonRes.json();
  return res;
}

export async function getChartLineByLast12andLevel(id_company, level) {
  const jsonRes = await fetch(
    `${retrieve_last_12_months_by_level_tests}${id_company}/${level}`,
    {
      method: "GET",
      headers: prefixHeaders,
    }
  );
  const res = await jsonRes.json();
  return res;
}

export async function getChartLineByYearAndLevel(id_company, curYear, level) {
  const jsonRes = await fetch(
    `${retrieve_by_year_and_level_tests}${id_company}/${curYear}/${level}`,
    {
      method: "GET",
      headers: prefixHeaders,
    }
  );
  const res = await jsonRes.json();
  return res;
}
