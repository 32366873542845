import React, { useEffect, useState, useMemo, useCallback } from "react";

import "./styles.scss";

import { connect } from "react-redux";
import {
  getChartLineYears,
  getSpeedchartData,
  get_tests_grouped_by_level,
} from "../../services/chart";
import { ifLoading } from "../../utilities/loading/ifLoading";

import { getContent } from "../../services/content";

import moment from "moment";
import PhisingInfo from "./PhisingInfo/PhisingInfo";
import SpeedMeeterChart from "./speedmeeterChart/SpeedMeeterChart";
import ChartComp from "./chartcomp/ChartComp";
import LoadingAllScreen from "../../utilities/LoadingAllScreen/LoadingAllScreen";
import PhisingLevels from "./PhisingLevels/PhisingLevels";
import DropDown from "../../utilities/dropdown/DropDown";


function PhisingPageContainer({ user }) {
  /** state staff */
  const [risk, setrisk] = useState(0);
  const [percentageRounded, setpercentageRounded] = useState(0);
  const [mailSent, setmailSent] = useState(0);
  const [mailClicked, setmailClicked] = useState(0);
  const [mailopend, setmailopend] = useState(0);

  const [loading, setloading] = useState(true);
  const [showAttacks, setshowAttacks] = useState(false);
  const [chartLoading, setchartLoading] = useState(false);
  const [showLineChart, setshowLineChart] = useState(true);

  const [testsArr, settestsArr] = useState([]);
  const [datesTestArr, setdatesTestArr] = useState([]);
  const [allYears, setallYears] = useState([]);

  const [prevTest, setprevTest] = useState(null);
  const [curLevel, setcurLevel] = useState(0);
  const [curYear, setcurYear] = useState("");

  const [smthWentWrongMess, setsmthWentWrongMess] = useState("");
  const [defaultTestName, setdefaultTestName] = useState("");
  const [sentMailText, setsentMailText] = useState("");
  const [openedMailText, setopenedMailText] = useState("");
  const [clickedMailText, setclickedMailText] = useState("");
  const [clickedRateText, setclickedRateText] = useState("");
  const [chartTitle, setchartTitle] = useState("");
  const [riskText, setriskText] = useState("");
  const [testName, settestName] = useState("");
  const [createdatDate, setcreatedatDate] = useState("");
  const [activeColor, setactiveColor] = useState("");

  /** */

  useEffect(() => {
    (async () => {
      getAllPageContent();
      await getspeeddata();
      await getChartYears();
    })();
  }, []);
  useEffect(() => {
    (async () => {
      await getTheChartLineGroubedByLevels();
    })();
  }, [curYear]);

  function getAllPageContent() {
    getContent("phising_test_page").then((data) => {
      const arr = data.content;
      setdefaultTestName(arr[0].value);
      setsentMailText(arr[1].value);
      setopenedMailText(arr[2].value);
      setclickedMailText(arr[3].value);
      setclickedRateText(arr[4].value);
      setchartTitle(arr[5].value);
      setriskText(arr[6].value);
    });
  }

  async function getspeeddata() {
    try {
      const res = await getSpeedchartData(user.id_company);

      if (res) {
        setrisk(() => res.risk / 100);
        setpercentageRounded(res.click_rate_percentage_rounded);
        setmailSent(res.sent);
        setmailClicked(res.clicked);
        setmailopend(res.opened);
        if (res.phishing_test === null) {
          console.log("it is true");
          settestName(defaultTestName);
          setcreatedatDate("No test created");
        } else {
          settestName(res.phishing_test.name);
          setcreatedatDate(parseDateItem(res.phishing_test.created_at));
        }
        setloading(false);
      } else {
        setloading(false);
        setsmthWentWrongMess(
          "Something went wrong in the server. Please try again later"
        );
      }
    } catch (error) {
      // alert(error.message)
      // alert("Something went wrong in the server. Please try again later")
    }
  }

  function get_the_color(precent) {
    if (precent >= 0 && precent <= 25) return "#008000";
    else if (precent >= 26 && precent <= 50) return "#d48e04";
    else if (precent >= 51 && precent <= 75) return "#d46604";
    else return "#c90700";
  }

  const handleRiskData = (payload) => {
    setrisk(payload.risk / 100);
    settestName(payload.name);
    setpercentageRounded(payload.statistics.click_rate_percentage_rounded);
    setmailSent(payload.statistics.sent);
    setmailClicked(payload.statistics.clicked);
    setmailopend(payload.statistics.opened);
    setcreatedatDate(parseDateItem(payload.created_at));
    setactiveColor(get_the_color(payload.risk));
    setTimeout(() => {
      setchartLoading(false);
    }, 1500);
  };
  function handleActiveDot(arr = [], id) {
    return [...arr].map(item => {
      if (item.id === id) {
        return {
          ...item,
          active: true,
        };
      } else if (item.active === true) {
        return {
          ...item,
          active: false,
        };
      } else return item;
    })
  }
  function handleNotActiveDot(arr = []) {
    return [...arr].map(item => {
      return {
        ...item,
        active: false,
      };
    })
  }
  function handleUpdatePointInlevel(levels = [], payload) {
    let x = [...levels].map(arr => {
      if (arr.levelIdx === payload.level) {
        const res = handleActiveDot(arr.level, payload.id)
        return {level : [...res], levelIdx : arr.levelIdx, color : arr.color}
      }
      return {level : [...handleNotActiveDot(arr.level)], levelIdx : arr.levelIdx, color : arr.color}
    })


    return x
  }
  function onClickDot(payload, curYear) {
    if (payload.id == prevTest) {
      return;
    }

    setprevTest(payload.id);
    setchartLoading(true);
    handleRiskData(payload);
    settestsArr(handleUpdatePointInlevel(testsArr, payload))
  }

  function parseDateItem(date = Date) {
    let monthDay = moment(date).format("MMM D").split(" ");
    return monthDay[1] + "/" + monthDay[0];
  }

  function handleDates(level = []) {
    try {
      level.tests.forEach((item) => {
        setdatesTestArr((dates) => [...dates, item.sent_at]);
      });
    } catch (error) {}
  }

  async function getChartYears() {
    const res = await getChartLineYears(user.id_company);
    let arr = [...res.years];
    setallYears(arr);
  }

  function getTests(arr = []) {
    if (!arr.tests) {
      return [];
    }

    return arr.tests.map((item, idx) => {
      return {
        ...item,
        index: idx + 1,
        // chartName: parseDateItem(item.sent_at),
        // chartName: `${idx + 1} / N${item.level}`,
        chartName: `${idx + 1}${item.level}`,
        risk: item.statistics.risk,
        active: idx === arr.length - 1 ? true : false,
      };
    });

    // let lastArrRisk = arr[arr.length - 1].statistics.risk;
    // setactiveColor(get_the_color(lastArrRisk));
  }

  const [mxEelementInTest, setmxEelementInTest] = useState(0);
  function getMaxEele(arr = []) {
    let cur = mxEelementInTest;
    arr.forEach((item) => {
      if (item.statistics.risk > cur) cur = item.statistics.risk;
    });
    setmxEelementInTest(cur);
  }

  /**---- levels */
  function handleCurYear(year = "") {
    setcurYear(year);
    // console.log(testsArr)
  }
  function getColor(idx) {
    let arr = ["#ff3333", "#ffc600","#00a0ff","#008000"];
    return arr[idx];
  }
  async function getTheChartLineGroubedByLevels() {
    try {
      const res = await get_tests_grouped_by_level(
        user.id_company,
        curYear,
        (err) => {
          alert(err);
        }
      );
      //
      settestsArr([]);
      setdatesTestArr([]);
      settestsArr(() => {
        let arr = [];
        for (const key in res) {
          let level = getTests(res[key]);
          handleDates(level);
          getMaxEele(level);
          arr.push({ levelIdx: key, level, color: getColor(key - 1) });
        }

        return arr;
      });
    } catch (error) {}
  }
  /** */
  const SpeedMemo = useMemo(
    () => (
      <SpeedMeeterChart
        testName={testName}
        risk={risk}
        get_the_color={get_the_color}
        riskText={riskText}
        loading={chartLoading}
      />
    ),
    [risk, testName, chartLoading]
  );
  function getArrOfTicks() {
    let arr = [];
    for (let i = 0; i <= 100; i += 10) {
      arr.push(i);
    }
    return arr;
  }
  if (loading) {
    return ifLoading(100);
  }

  if (smthWentWrongMess !== "") {
    return (
      <div>
        <h1 style={{ color: "white", marginTop: "100px", padding: "50px" }}>
          {smthWentWrongMess}
        </h1>
      </div>
    );
  }

  return (
    <div className="container__">
      <LoadingAllScreen loading={chartLoading} />

      <div className="top__">
        <div className="page__content__">
          <PhisingLevels
            curLevel={curLevel}
            handleChartLineLevel={setcurLevel}
          />

          {SpeedMemo}

          <PhisingInfo
            loading={chartLoading}
            createdatDate={createdatDate}
            sentMailText={sentMailText}
            mailSent={mailSent}
            openedMailText={openedMailText}
            mailopend={mailopend}
            clickedMailText={clickedMailText}
            mailClicked={mailClicked}
            clickedRateText={clickedRateText}
            percentageRounded={percentageRounded}
          />
        </div>
      </div>
      {testsArr.length === 0 && (
        <div className="notest__container">
          <p>No tests have been made</p>
        </div>
      )}

      <div className="line-chart-container">
        <div className="content__" style={{ width: "100%" }}>
          <div className="top-chart-content">
            <div className="right-left">
              <DropDown onHandleClick={handleCurYear} arr={allYears} />
            </div>
            <div className="title">
              <h1 dangerouslySetInnerHTML={{ __html: chartTitle }}></h1>
            </div>
            <div className="right-left"></div>
          </div>

          {showLineChart && testsArr.length && (
            <div className="linechart__">
              <ChartComp
                curLevel={curLevel}
                testsArr={testsArr}
                getArrOfTicks={getArrOfTicks}
                risk={risk}
                mxEelementInTest={mxEelementInTest}
                onClickDot={onClickDot}
                curYear={curYear}
                activeColor={activeColor}
                parseDateItem={parseDateItem}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapState = ({ user }) => ({ user });
export default connect(mapState)(PhisingPageContainer);
