import React from 'react'
import PhisingPageContainer from '../components/app/phisingPageContainr/PhisingPageContainer'


function PhisingPageScreen() {
    return (
        <PhisingPageContainer />
    )
}

export default PhisingPageScreen
