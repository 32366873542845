import { CircularProgress } from "@material-ui/core";
import React from "react";
import "./styles.scss";

const PhisingInfo = ({
  createdatDate,
  sentMailText,
  mailSent,
  mailopend,
  openedMailText,
  mailClicked,
  clickedMailText,
  percentageRounded,
  clickedRateText,
  loading,
}) => {
  const loadingIcon = (
    <CircularProgress
      size={20}
      thickness={3}
      style={{ color: "#ff6500", marginRight: "3px" }}
    />
  );
  return (
    <div className="phising-info">
      <div className="content">
        <div className="title">
          <h2>{loading ? loadingIcon : createdatDate}</h2>
        </div>

        <div className="info">
          <p dangerouslySetInnerHTML={{ __html: sentMailText }}></p>{" "}
          {/* <p> {loading ? loadingIcon :  mailSent} </p> */}
          <p> {loading ? loadingIcon : mailSent}</p>
        </div>

        <div className="info">
          <p dangerouslySetInnerHTML={{ __html: openedMailText }}></p>{" "}
          <p> {loading ? loadingIcon : mailopend}</p>
        </div>
        <div className="info">
          <p dangerouslySetInnerHTML={{ __html: clickedMailText }}></p>{" "}
          <p> {loading ? loadingIcon : mailClicked}</p>
        </div>
        <div className="info">
          <p dangerouslySetInnerHTML={{ __html: clickedRateText }}></p>{" "}
          <p> {loading ? loadingIcon : percentageRounded} %</p>
        </div>
      </div>
    </div>
  );
};

export default PhisingInfo;
