import { Button } from "@material-ui/core";
import React from "react";
import "./styles.scss";
function UserAvatar({
  imgSrc = "",
  imgWidth = 250,
  imgHeight = 250,
  onRemove,
  hideIcon = false,
}) {
  return (
    <div className="container">
      <img
        style={{ width: imgWidth, height: imgHeight }}
        src={imgSrc}
        className="img"
        alt=""
      />
      {/* {hideIcon === false && (
        <img onClick={onRemove} style={{ width: 30, height: 30 }} src={removeimg} alt="" className="xicon" />
      )} */}
    </div>
  );
}

export default UserAvatar;
// {/* <input onClick={onRemove} type="button" value="X" className="xicon" /> */}
