import React, { useState } from "react";

function CustomizeDot(props) {
  const { cx, cy, stroke, payload, value, activeColor } = props;
  const { active } = payload;

  function levelColors(idx) {
    let colors = ["#ff3333", "#ffc600", "#00a0ff", "#008000"];
    return colors[idx - 1];
  }
  if (active === true) {
    return (
      <svg
        x={cx - 12.3}
        y={cy - 12}
        width={25}
        height={25}
        fill="white"
        viewBox="0 0 200 200"
      >
        <path
          d="
            M 100, 100
            m -75, 0
            a 75,75 0 1,0 150,0
            a 75,75 0 1,0 -150,0
            "
        />
      </svg>
    );
  }
  return (
    <svg
      viewBox="0 0 200 200"
      x={cx - 12.3}
      y={cy - 12}
      width={25}
      height={25}
      fill={levelColors(+payload.level)}
      // fill={activeColor}
    >
      <path
        d="
            M 100, 100
            m -75, 0
            a 75,75 0 1,0 150,0
            a 75,75 0 1,0 -150,0
            "
      />
    </svg>
  );
}

export default CustomizeDot;
