import React from "react";

function CustomizedAxisTick(props) {
  const { x, y, payload } = props;
  function levelColors(idx) {
    let colors = ["#ff3333",  "#ffc600","#00a0ff", "#008000"];
    return colors[idx - 1];
  }
  return (
    <g transform={`translate(${x + 4},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill={levelColors(+payload.value.slice(-1))}
      >
        {payload.value.slice(0, -1)}
      </text>
    </g>
  );
}

export default CustomizedAxisTick;
