import React from "react";
import { Button } from "@material-ui/core";
import "./styles.scss";

const NextBtn = ({onClick , ...props}) => {
  return (
    <div className="custom-next-btn">
      <Button
        onClick={onClick}
        {...props}
      >
        {props.children}
      </Button>
    </div>
  );
};

export default NextBtn;
