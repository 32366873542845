import React from "react";
import ResetPass from "../components/Accounts/ResetPass/ResetPass";


function ResetPassScreen() {
  
  return ( 
    <ResetPass />
  );
}

export default ResetPassScreen;
