import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./components/app/routes/PrivateRoute";
import PublicRoute from "./components/app/routes/PublicRoute";
import Layout from "./components/Layout/Layout";
import ChangePasswordScreen from "./screens/ChangePassScreen";
import PhisingPageScreen from "./screens/PhisingPageScreen";
import ForgotPassScreen from "./screens/ForgotPassScreen";
import LoginScreen from "./screens/LoginScreen";
import ResetPassScreen from "./screens/ResetPassScreen";
function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <PublicRoute path="/auth/login" component={LoginScreen} />
          <PublicRoute path="/auth/forgotpass" component={ForgotPassScreen} />
          <PrivateRoute path="/chart" component={PhisingPageScreen} />
          <PrivateRoute
            path="/profile/change-pass"
            component={ChangePasswordScreen}
          />

          <PublicRoute
            path="/auth/reset_pass/:token"
            component={ResetPassScreen}
          />
          <Redirect to="/auth/login" />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
