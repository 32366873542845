import { prefixApi, prefixHeaders } from "./prefix";

const login_api = prefixApi + "auth/login";
const api_send_email = `${prefixApi}auth/reset_password`;


export async function login(email = "", password = "", remember = 0) {
  var details = {
    email: email,
    password: password,
    remember: remember,
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  formBody = formBody.join("&");
  return fetch(login_api, {
    method: "POST",
    headers: prefixHeaders,
    body: formBody,
  }).then((res) => res.json());
}

export async function email_reset_password(email = "") {
  var details = {
    email: email,
  };
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(api_send_email, {
    method: "POST",
    headers: prefixHeaders,
    body: formBody,
  }).then((res) => res.json());
}