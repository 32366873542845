import { prefixApi, prefixHeaders } from "./prefix";

const api_content = `${prefixApi}content/get/`;

export const getContent = (type) => {
  try {
    var details = {
      // key: type,
      page: type,
    };
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return fetch(api_content, {
      method: "POST",
      headers: prefixHeaders,
      body: formBody,
    }).then((res) => res.json());
  } catch (error) {
    console.log(error)
  }
};

