import { CircularProgress } from "@material-ui/core";
import './styles.scss'

export const ifLoading = (fntSize = 50) => {

    return (
      <div className="term-container">
        <CircularProgress
          size={fntSize}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    );

}