import React from "react";
import { connect } from "react-redux";
import Header from "../Header_footer/Header/Header";
// import Alert from "../utilities/AlertModal/AlertModal";
import "./styles.scss";

const Layout = (props) => {
  const handleLogOut = () => {};

  return (
    <div className="layout font">
      <Header handleLogOut={handleLogOut} />
      
      <div className="layout-content">{props.children}</div>
      
    </div>
  );
};
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(Layout);
