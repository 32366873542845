import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { showErrorMessage } from "../../Accounts/formsService";

// service
import { email_reset_password } from "../../services/auth";

// Utilites
import FinishProfileInfo from "../../utilities/modals/FinishProfileInfo/FinishProfileInfo";
import Alert from "../../utilities/modals/AlertModal/Alert";
import NextBtn from "../../utilities/Buttons/NextBtn/NextBtn";

import "./styles.scss";

const ChangePassword = () => {
  // const [showFormError, setshowFormError] = useState(false);
  // const [showEmailWasSent, setshowEmailWasSent] = useState(false);
  const [alertStatus, setalertStatus] = useState(false);

  // const [title, settitle] = useState("");
  // const [btnText, setBtnText] = useState("");
  // const [loading, setLoading] = useState("");
  // const [passwordError, setpasswordError] = useState("");
  // const [smthWentWrongError, setsmthWentWrongError] = useState("");
  const [modalEmailWasntSent, setmodalEmailWasntSent] = useState(false);

  //   useEffect(() => {
  //     setLoading(true);
  //     ("reset_password_page").then((data) => {
  //       const arr = data.content;
  //       settitle(arr[0].value);
  //       setBtnText(arr[1].value);
  //       setpasswordError(arr[2].value);
  //       setsmthWentWrongError(arr[3].value);
  //       setLoading(false);
  //     });
  //   }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const changeUserName = (e) => {
    const { email } = e;
    email_reset_password(email).then((data) => {
      if (data.status === 1) {
        // setshowEmailWasSent(true);
        setalertStatus(true);
      } else if (data.status == 0) {
        setmodalEmailWasntSent(true);
      }
    });
  };

  //   if (loading) {
  //     return (
  //       <div style={{ textAlign: "center" }}>
  //         <CircularProgress
  //           size={50}
  //           thickness={4}
  //           style={{ color: "#ff6500", marginRight: "3px" }}
  //         />
  //       </div>
  //     );
  //   }

  function handleCloseAlert() {
    setalertStatus(false);
  }
  return (
    <div className="change-password-container">
      <FinishProfileInfo
        handleCloseFinishModal={handleCloseAlert}
        finishModalStatus={alertStatus}
        shouldCloseOnClick={true}
        text="Mail envoyé !
Si vous ne le trouvez pas dans votre boîte de réception, regardez dans vos courriers indésirables."
      />
      <Alert
        alertStatus={modalEmailWasntSent}
        handleCloseAlert={() => setmodalEmailWasntSent(false)}
        title="Adresse Email incorrecte"
      />
      <form
        onSubmit={handleSubmit(changeUserName)}
        className="change-password-section"
      >
        {/* <p
          className="reset-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p> */}
        <div className="title-container">
          <h3 className="title">Modifier le mot de passe</h3>
          <span>
            Nous allons vous envoyer un mail avec un lien pour changer votre mot
            de passe.
          </span>
        </div>

        <div className="input-container">
          <div className="input-content">
            <p>Votre adresse mail :</p>
            <input
              className={`form-control ${errors.password && "input-error"}`}
              autoComplete="true"
              type="text"
              {...register("email", { required: true })}
              placeholder="Email"
            />
          </div>
          {/* <button className="validate-btn btn btn-lg btn-rounded btn-warning">
            VALIDER
          </button> */}
          <NextBtn
            type="submit"
            className="validate-btn btn btn-lg btn-rounded btn-warning"
          >
            VALIDER
          </NextBtn>
        </div>
        {/* {showEmailWasSent && (
          <div className="show-success">
            <p>
              Link was sent successfully please check your email to change your
              password
            </p>
          </div>
        )} */}
        <div className="error-section">
          {errors.email && showErrorMessage("Ce champ doit être complété")}
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
