import {SET_USER_NAME, SET_USER_IMG, SET_USER_ID, SET_USER_ID_COMPANY} from '../types'

export const setUserName = (name) => ({
    type : SET_USER_NAME,
    name
})
export const setUserImg = (img) => ({
    type : SET_USER_IMG,
    img
})
export const setUserId = (id) => ({
    type : SET_USER_ID,
    id
})
export const setUserIdCompany = (id) => ({
    type : SET_USER_ID_COMPANY,
    id
})
