import React, { useState, useEffect } from "react";
import "./dropdwonstyles.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function DropDown({
  arr = [],
  onHandleClick = (f) => f,
  widthOfContainer = "100%",
  title = "company",
}) {
  const [showDropList, setshowDropList] = useState(false);
  const [choosenItem, setchoosenItem] = useState({
    text: "2022",
    id: -1,
  });
  function handleOpenlist() {
    setshowDropList(!showDropList);
  }
  function handleClickItem(item) {
    onHandleClick(item);
    setshowDropList(false);
    setchoosenItem({ text: item, id: 0 });
  }
  return (
    <div className="drondown_container" style={{ width: widthOfContainer }}>
      
      <div className="header" onClick={handleOpenlist}>
        <p>{choosenItem.text}</p>
        <div className="icon_container">
          {showDropList ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {showDropList && (
        <div className="droplist">
          {
            (arr) && arr.map(item => <p key={item} onClick={() => handleClickItem(item)}>
              {item}
            </p>)
          }
        </div>
      )}
    </div>
  );
}

export default DropDown;
