import {SET_USER_NAME, SET_USER_IMG, SET_USER_ID, SET_USER_ID_COMPANY} from '../types'
const initState = {
    name : null,
    id : null,
    img : null,
    id_company : null
}

export function user_reducer(state = initState, action) {
    switch (action.type) {
      case SET_USER_ID:
        return { ...state, id: action.id };
        break;
      case SET_USER_NAME:
        return { ...state, name: action.name };
        break;
      case SET_USER_IMG:
        return { ...state, img: action.img };
        break;
      case SET_USER_ID_COMPANY : return {...state, id_company : action.id}
      default: return state;
        break;
    }
  }