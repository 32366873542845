import React  from "react";
import ForgotPass from "../components/Accounts/ForgotPass/ForgotPass";


function ForgotPassScreen() {
  
  return ( 
    <ForgotPass />
  );
}

export default ForgotPassScreen;
