import React from 'react'

import ChangePassword from '../components/Profile/ChangePass/ChangePass'

function ChangePasswordScreen() {
    return (
        <ChangePassword />
    )
}

export default ChangePasswordScreen
