
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {user_reducer} from './userReducer';
import {projectReducer} from './projectReducer'

const persistConfig = {
    key: 'phishing',
    storage,
    whitelist: ['user']
}
const rootReducer = combineReducers({user : user_reducer, appRedux : projectReducer})

export default persistReducer(persistConfig, rootReducer)