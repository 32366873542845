import { CircularProgress } from "@material-ui/core";
import React from "react";

import "./styles.scss";

const LoadingAllScreen = ({ loading }) => {
  if (loading) {
    return (
      <div className="loading-all-screen">
        <CircularProgress
          size={80}
          thickness={4}
          style={{ color: "#ff6500", marginRight: "3px" }}
        />
      </div>
    );
  }
  return null
};

export default LoadingAllScreen;
