
import { SET_CUR_PATH } from "../types";

export const projectReducer = (state = {curPath : '/'}, action) => {
    switch (action.type) {
        case SET_CUR_PATH: return {...state , curPath : action.path}
            break;
        default: return state
            break;
    }
}