import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { set_cur_path } from '../../../redux/actions/projectactions';

function PrivateRoute({ user, component: Component ,rest }) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(set_cur_path(window.location.pathname))
    }, [])
    return (
        <Route {...rest}
            render={
                (props) => (user.id) ? (<Component 
                 {...props} />) :
                    (
                        <Redirect to="/auth/login" />
                    )
            }
        />
    )
}
const mapState = ({ user }) => ({ user })

export default withRouter(connect(mapState)(PrivateRoute))
