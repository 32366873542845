import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";

// material ui
// import { CircularProgress } from "@material-ui/core";
// import WarningIcon from "@material-ui/icons/Warning";

// Service

// utilites

import "./styles.scss";

const ResetPass = () => {
  const { token } = useParams();
  const go = useHistory();

  useEffect(() => {
    if (token.length < 1) {
      alert("reset code not right :(");
      go.push("/auth/forgotpass");
    }
  }, []);

  const [pass1, setpass1] = useState("");
  const [pass2, setpass2] = useState("");
  // const [showFormError, setshowFormError] = useState(false);
  // const [alertStatus, setalertStatus] = useState(false);
  // const [passDontMatchError, setpassDontMatchError] = useState(false);
  // const [idOfuser, setidOfuser] = useState("");
  const [title, settitle] = useState("");
  // const [btnText, setBtnText] = useState("");
  // const [loading, setLoading] = useState("");
  // const [passwordError, setpasswordError] = useState("");
  // const [smthWentWrongError, setsmthWentWrongError] = useState("");

  function checkValid(pass = "") {
    let hasNum = false,
      hasUprCase = false,
      hasLowCase = false;
    for (let i = 0; i < pass.length; i++) {
      let curChar = pass[i].toUpperCase();
      if (isNaN(curChar) === true && curChar === pass[i]) {
        hasUprCase = true;
      }
      curChar = pass[i].toLowerCase();
      if (isNaN(curChar) === true && curChar === pass[i]) {
        hasLowCase = true;
      }
      curChar = parseInt(pass[i], 10);
      if (isNaN(curChar) === false) {
        hasNum = true;
      }
    }
    if (hasLowCase && hasUprCase && hasNum && pass.length >= 12) return true;
    return false;
  }
  function handlesubmit() {
    if (pass1 !== pass2) {
      alert("password not matches");
      return;
    }
    if (checkValid(pass1) !== true) {
      alert(
        "password must be at least 12 and has num, uppercase and lower case"
      );
      return;
    }
  }
  return (
    <div className="reset-pass-container">
      <form onSubmit={handlesubmit} className="reset-pass-section">
        <p
          className="reset-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></p>
        <div className="input-container">
          <input
            value={pass1}
            onChange={(e) => setpass1(e.target.value)}
            type="password"
            placeholder="Entrez le nouveau mot de passe"
          />

          <input
            value={pass2}
            onChange={(e) => setpass2(e.target.value)}
            type="password"
            placeholder="Confirmez le nouveau mot de passe"
          />
          <input type="button" value="submit" onClick={handlesubmit} />
        </div>
      </form>
    </div>
  );
};

export default ResetPass;
